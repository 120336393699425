window.$ = require('jquery');
require('jquery-validation');
require('jquery-validation/dist/localization/messages_es');
window.intlTelInput = require('intl-tel-input');
$.validator.addMethod("validPhone", function (value, element) {
    var iti = window.intlTelInputGlobals.getInstance(element);
    return iti.isValidNumber();
}, "Invalid phone");
//require('./bootstrap');
import { Tooltip } from 'bootstrap';
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl, {
        trigger: 'hover'
    });
});
//require('slick-slider');
/*import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';*/
